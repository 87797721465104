<template>
  <div>
    <div
      class="uk-container uk-margin-top uk-margin-medium-bottom"
      v-if="!loading"
    >
      <div class="row">
        <div class="col-md-12">
          <a
            href="#"
            @click.prevent="update"
            class="btn btn-success float-right"
            ><i class="lni lni-save mr-2"></i>Save</a
          >
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label>Page Title</label>
            <input
              type="text"
              v-model="content.intro.title"
              placeholder="title"
              class="form-control"
            />
          </div>
          <div class="form-group">
            <label>Page Intro</label>
            <editor v-model="content.intro.content" height="300px" />
          </div>
        </div>
      </div>
      <a
        href="#"
        @click.prevent="content.funders.push({ features: '' })"
        class="float-right"
        ><i class="lni lni-plus mr-2"></i>Add Funder</a
      >
      <label>Funders</label>
      <div
        class="`card mb-3"
        v-for="(funder, i) in content.funders"
        :key="`funder-${i}`"
      >
        <div
          :class="`card-body uk-card-${i % 2 == 0 ? 'secondary' : 'primary'}`"
        >
          <div class="row">
            <div class="col-md-12 mb-3">
              <a href="#" class="float-right" @click.prevent="content.funders.splice(i, 1)"
                ><i class="lni lni-trash-can mr-2"></i>Delete</a
              >
            </div>
            <div class="col-md-3">
              <div>
                <input class="mb-2" type="file" ref="funder_logos" />
                <img
                  :src="funder.logo"
                  :alt="funder.title"
                  style="height: 100px"
                />
                <h2 class="mb-0">
                  <input
                    type="text"
                    v-model="funder.title"
                    placeholder="Title"
                    class="form-control mt-3"
                  />
                </h2>
                <input
                  type="text"
                  placeholder="Link"
                  v-model="funder.link"
                  class="form-control mt-4"
                />
              </div>
            </div>
            <div class="col-md-9">
              <editor v-model="funder.features" height="500" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <loading v-if="loading" />
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch();
  },

  data() {
    return {
      content: {},
      loading: true,
    };
  },

  methods: {
    fetch() {
      this.loading = true;

      this.$axios.get("/api/v1/pages/get-funded").then((response) => {
        this.content = response.data.content;
        this.loading = false;
      });
    },

    update() {
      var data = new FormData
      data.append('intro[title]', this.content.intro.title)
      data.append('intro[content]', this.content.intro.content)
      
      var logos = this.$refs.funder_logos
      
      data.funders = this.content.funders.forEach((funder, i) => {
        data.append(`funders[${i}][title]`, funder.title)
        data.append(`funders[${i}][link]`, funder.link)
        data.append(`funders[${i}][logo]`, funder.logo)
        data.append(`funders[${i}][features]`, funder.features)

        if(logos[i].files[0]) {
          data.append(`funders[${i}][new_logo]`, logos[i].files[0])
        }
      })

      this.loading = true
      this.$axios.post('/api/v1/admin/pages/get-funded', data).then(() => {
        this.fetch()
      })
    }
  },
};
</script>
